////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-secondaryColor {
  background-color: rgba(244, 244, 244, 1);
}
.bg-thirdColor {
  background-color: rgba(244, 244, 244, 1);
}
.bg-fourthColor {
  background-color: rgba(51, 51, 51, 1);
}
.bg-fifthColor {
  background-color: rgba(202, 202, 202, 1);
}
.bg-sixthColor {
  background-color: rgba(51, 51, 51, 1);
}
.bg-seventhColor {
  background-color: rgba(51, 51, 51, 1);
}
